.containerActionWar {
	grid-column: 2/5;
	grid-row: 4/5;
	font-size: 0.875em;
	display: flex;
	flex-direction: column;
	text-align: center;
	justify-content: center;
	span {
		margin-bottom: 0.75em;
	}
	.actionWar {
		&Info {
			background: #141416;
			color: #26ded0;
			font-size: 0.75em;
			font-weight: 500;
			padding: 0.25em;
			border: 1px solid #26ded0;
			box-shadow: 0.25em 0.25em 0 #26ded0;
			cursor: pointer;
		}
		&Button {
			padding: 0.25em 0.5em;
			width: 70%;
			align-self: center;
			color: black;
			background-color: #26ded0;
			font-weight: 500;
			border-radius: 4px;
			cursor: pointer;
		}
	}
}
.sendInput {
	margin: 1rem 0;
}
.btnConfirmSend {
	align-self: end;
}
@media (min-width: 768px) {
	.containerActionWar {
		.actionWar {
			&Info {
				padding: 0.5em 0.25em;
				width: 80%;
				align-self: center;
				font-size: 1em;
			}
			&Button {
				padding: 0.5em 0;
				width: 45%;
			}
		}
	}
}
@media (min-width: 1024px) {
	.containerActionWar {
		// grid-column: 3/4;
		.actionWar {
			&Info {
				padding: 0.75em 0.25em;
				width: 60%;
				align-self: center;
				font-size: 1em;
				box-shadow: 0.5em 0.5em 0 #26ded0;
			}
			&Button {
				padding: 0.75em 0;
				width: 30%;
			}
		}
	}
}
@media (min-width: 1440px) {
	.containerActionWar {
		// grid-column: 3/4;
		.actionWar {
			&Info {
				font-size: 1.25em;
			}
			&Button {
				padding: 0.75em 0;
				width: 20%;
			}
		}
	}
}