
.containerRewardWar {
	grid-column: 2/5;
	grid-row: 5/6;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-template-rows: repeat(1, 1fr);
	align-items: center;
	margin-top: 1em;
	.h5 {
		grid-column: 1/3;
		grid-row: 1;
		text-decoration: underline #26ded0 2px;
		text-underline-offset: 3px;
		align-self: end;
		margin-bottom: 0.5em;
	}
	.rewardMap {
		grid-column: 1/3;
		grid-row: 2/3;
		display: flex;
		justify-content: center;
		border-radius: 8px;
		// background: rgba(255, 255, 255, 0.05);
		// box-shadow: 0px 2px 4px rgb(255 255 255 / 10%);
		.picture {
			width: 65%;
			img {
				border-radius: 8px;
			}
		}
		// &Info {
        //     width: 50%;
		// 	display: flex;
		// 	flex-direction: column;
		// 	justify-content: center;
		// 	padding-left: 0.5em;
		// }
	}
}
@media (min-width: 768px) {
	.containerRewardWar {
		.h5 {
			font-size: 1.25em;
			text-underline-offset: 4px;
		}
		.rewardMap {
			&Info {
				padding-left: 1em;
				font-size: 1.25em;
			}
		}
	}
}
@media (min-width: 1024px) {
	.containerRewardWar {
		.h5 {
			font-size: 1.5em;
			text-underline-offset: 5px;
		}
		.rewardMap {
			&Info {
				padding-left: 1.5em;
				font-size: 1.5em;
			}
		}
	}
}
@media (min-width: 1440px) {
	.containerRewardWar {
		.h5 {
			font-size: 2.625em;
			text-underline-offset: 10px;
		}
		.rewardMap {
			&Info {
				padding-left: 2em;
				font-size: 2em;
			}
		}
	}
}