@mixin countdownValueNext() {
    display: flex;
    justify-content: center;
    align-items: baseline;
    font-size: 2em;
	@media (min-width: 1024px) {
		font-size: 2.5em;
	}
	@media (min-width: 1440px) {
		font-size: 3.125em;
	}
}
.containerInfoWar{
	grid-column: 2/5;
	justify-self: center;
	grid-row: 3;
	width: 65%;
	min-height: 13em;
	position: relative
}
.containerCountdownNextWar {

	padding: 0.25em;
	border: 1px solid #26ded0;
	box-shadow: 0.25em 0.25em 0 #26ded0;
	text-align: center;
	width: 100%;
	position: absolute;
	top: 25%;

	
	// cursor: pointer;
	// margin-top: 0.625em;
	.h5 {
		font-size: 0.875em;
		color: #26ded0;
		text-underline-offset: 3px;
		margin-bottom: 0.5em;
	}
	.descNextWar {
		font-size: 0.625em;
	}
	.span {
		font-size: 0.625rem;
		position: relative;
		&:after {
			content: attr(data-text);
			transform: translateX(-100%);
			position: absolute;
			text-decoration: underline #26ded0 2px;
			text-underline-offset: 3px;
			top: -1px;
		}
	}
	.countdown {
		&Designation {
			font-size: 0.75em;
		}	
		&ValueDays {
			@include countdownValueNext();
		}
	}
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
}
@media (min-width: 1440px) {
}