// @import "mixins";
@mixin countdownValue() {
    grid-row: 2;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    font-size: 2em;
	@media (min-width: 1024px) {
		font-size: 2.5em;
	}
	@media (min-width: 1440px) {
		font-size: 3.125em;
	}
}
@mixin countdownDesignation() {
	grid-column: 2/6;
	grid-row: 1;
	text-align: center;
	font-size: 0.75em;
}
.containerCountdownWar {
	grid-column: 2/5;
	grid-row: 3;
	display: grid;
	grid-template-columns: repeat(6, 1fr);
	grid-template-rows: repeat(2, 1fr);
	column-gap: 0.875em;
	align-items: flex-end;
}	
.span {
	font-size: 0.5rem;

	&:after {
		content: attr(data-text);
		transform: translateX(-100%);
		position: absolute;
		text-decoration: underline #26ded0 2px;
		text-underline-offset: 3px;
	}
}
.countdown {
	&Designation {
		@include countdownDesignation();
		&Only{
			@include countdownDesignation();
			border: 1px solid #26ded0;
			box-shadow: 0.25em 0.25em 0 #26ded0;
		}
	}	
	&ValueDays {
		@include countdownValue();
		grid-column: 2/3;
	}
	&ValueHours {
		@include countdownValue();
		grid-column: 3/4;
	}
	&ValueMinutes {
		@include countdownValue();
		grid-column: 4/5;
	}
	&ValueSeconds {
		@include countdownValue();
		grid-column: 5/6;
	}
}
@media (min-width: 768px) {
	// .containerCountdownWar {
	// }
	.span {
		font-size: 0.6125rem;
		&:after {
			text-underline-offset: 4px;
		}
	}
	.countdown {
		&Designation {
			font-size: 0.825em;
		}
	}
}
@media (min-width: 1024px) {
	.containerCountdownWar  {
		grid-column: 3/4;
		min-width: 15em;
	}
	.span {
		font-size: 0.75rem;
		&:after {
			text-underline-offset: 5px;
		}
	}
	.countdown {
		&Designation {
			font-size: 1em;
			&Only {
				min-width: 15em
			}
		}
	}
}
@media (min-width: 1440px) {
	.containerCountdownWar {
		grid-column: 3/4;
		min-width: 15em;
	}
	.span {
		font-size: 1rem;
		&:after {
			text-underline-offset: 6px;
		}
	}
	.countdown {
		&Designation {
			font-size: 1em;
			&Only {
				min-width: 22em
			}
		}
	}
}