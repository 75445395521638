.containerMain {
	display: grid;
	grid-template-columns: repeat(5, 1fr);
	// grid-template-rows: repeat(10, 1fr);
	row-gap: 0.5em;
	.h1 {
		grid-column: 2/5;
		grid-row: 2/3;
		text-align: center;
		font-size: 1.1em;
		font-weight: 500;
		&:after {
			content: attr(data-text);
			transform: translateX(-100%);
			position: absolute;
			text-decoration: underline #26ded0 2px;
			text-underline-offset: 3px;
		}
	}
	.containerWinnerWar {
		grid-column: 2/5;
		grid-row: 13;
		.h5 {
			margin: 0.5em 0;
			&:after {
				content: attr(data-text);
				transform: translateX(-100%);
				position: absolute;
				text-decoration: underline #26ded0;
				text-underline-offset: 3px;
			}
		}
		.winnerName {
			background: #141416;
			color: #26ded0;
			font-size: 0.75em;
			font-weight: 500;
			padding: 0.25em;
			border: 1px solid #26ded0;
			box-shadow: 0.25em 0.25em 0 #26ded0;
			width: 50%;
			text-align: center;
		}
	}
}
.decoOne {
	grid-column: 1;
	grid-row: 3;
	
	.warriorsPict {
		position: absolute;
		transform: rotate(-7.3deg);
		margin-left: 0.75em;
		margin-top: 2.5em;
		z-index: 2;
		img{
			border-radius: 8px;
			width: 18%
		}
	}
	.mashed {
		position: absolute;
		width: 40%;
	}
}
.decoTwo {
	grid-column: 5;
	grid-row: 3;
	.warriorsPict {
		position: absolute;
		transform: rotate(3.72deg);
		z-index: 2;
		margin-right: 0.5em;
		img{
			border-radius: 8px;
			width: 85%;
		}
	}
	.mashed {
		position: absolute;
		right: 0;
		top: 15%;
		width: 40%;
	}
}

@media (min-width: 768px) {
	.containerMain {
		row-gap: 0.825em;
		.h1 {
			font-size: 1.5em;
			width: 75%;
    		justify-self: center;
			&:after {
				text-underline-offset: 4px;
			}
		}
	}
	.decoOne {		
		.warriorsPict {
			margin-left: 4em;
			margin-top: 0;
			img{
				width: 25%
			}
		}
		.mashed {
			top: 0;
			
		}
	}
	.decoTwo {
		.warriorsPict {
			margin-right: 2px;
			img{
				width: 53%;
			}
		}
		.mashed {
			top: 0;
			width: 35%;
		}
	}
}
@media (min-width: 1024px) {
	.containerMain {
		row-gap: 1em;
		.h1 {
			font-size: 3em;
			width: 100%;
			&:after {
				text-underline-offset: 5px;
			}
		}
		.containerWinnerWar {
			margin-bottom: 5em;
			.h5 {
				font-size: 1.5em;
				&:after {
					text-underline-offset: 5px;
				}
			}
			.winnerName {
				font-size: 1em;
			}
		}
	}
	.decoOne {		
		.warriorsPict {
			margin: 0;
			display: flex;
			justify-content: center;
			img{
				width: 37%
			}
		}
		.mashed {
			top: 0;
			
		}
	}
	.decoTwo {
		grid-column: 4;
		.warriorsPict {
			display: flex;
			justify-content: center;
			img{
				width: 32%;
			}
		}
		.mashed {
			width: 36%;
		}
	}
}
@media (min-width: 1440px) {
	.containerMain {
		row-gap: 2em;
		.h1 {
			font-size: 4.5em;
			&:after {
				text-underline-offset: 10px;
			}
		}
		.containerWinnerWar {
			margin-bottom: 7em;
			.h5 {
				font-size: 2.625em;
				&:after {
					text-underline-offset: 10px;
				}
			}
			.winnerName {
				font-size: 1.25em;
			}
		}
	}
	.decoOne {		
		.warriorsPict {
			img{
				width: 56%
			}
		}
		.mashed {
		}
	}
	.decoTwo {
		.warriorsPict {
			margin-left: 2em;
			justify-content: flex-end;
			img{
				width: 50%;
			}
		}
		.mashed {
		}
	}
}
@media (min-width: 1920px) {
	.containerMain {
		.h1 {
			&:after {
			}
		}
		.containerWinnerWar {
			.h5 {
				&:after {
				}
			}
			.winnerName {
			}
		}
	}
	.decoOne {		
		.warriorsPict {
			img{
			}
		}
		.mashed {
		}
	}
	.decoTwo {
		.warriorsPict {
			margin-left: 8em;
			img{
			}
		}
		.mashed {
		}
	}
}