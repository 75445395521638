.footer {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 1em 0;
	font-size: 0.65em;
	.img {
		width: 7em;
		height: auto;
	}
	.footerLink {
		width: 60%;
		display: flex;
		justify-content: space-around;
	}
	.divider{
		width: 60%;
		margin: 1em 0;
		border-bottom: 1px solid #e5e5e5;
	}
}
@media (min-width: 768px) {
	.footer {
		padding: 2.5em 0;
		.footerLink {
			width: 45%;
		}
	}
}
@media (min-width: 1024px) {
	.footer {
		.footerLink {
			width: 30%;
		}
	}
}
@media (min-width: 1440px) {
	.footer {
		.footerLink {
			width: 20%;
		}
	}
}