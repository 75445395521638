.containerRulesWar {
	grid-column: 2/5;
	grid-row: 7/11;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	margin-top: 1.5em;
	.h5 {
		text-decoration: underline #26ded0 2px;
		text-underline-offset: 3px;
		margin-bottom: 0.5em;
	}
	.rulesParagraph {
		padding: 0.5em 0;
		&:not(:last-child){
			border-bottom: 0.35px solid #b1b5c3;
		}
	}
	.rulesWar {
		&Text {
			font-size: 0.75em;
		}
		&Index {
			font-size: 1.25em;
			padding-right: 0.25em;
			&:after {
				content: attr(data-text);
				transform: translateX(-100%);
				position: absolute;
				color: #26ded0;
			}
		}
	}
}
@media (min-width: 768px) {
	.containerRulesWar {
		.h5 {
			font-size: 1.25em;
			text-underline-offset: 4px;
		}
		.rulesParagraph {
			display: flex;
		}
		.rulesWar {
			&Index {
				padding-right: 0.5em;
			}
		}
	}
}
@media (min-width: 1024px) {
	.containerRulesWar {
		.h5 {
			font-size: 1.5em;
			text-underline-offset: 5px;
		}
		.rulesParagraph {
			align-items: center;
		}
		.rulesWar {
			&Index {
				font-size: 1.5em;
				padding-right: 0.75em;

			}
		}
	}
}
@media (min-width: 1440px) {
	.containerRulesWar {
		.h5 {
			font-size: 2.625em;
			text-underline-offset: 10px;
		}
		.rulesWar {
			&Text {
				font-size: 1em;
			}
			&Index {
				font-size: 3.125em;
				padding-right: 0.25em;
			}
		}
	}
}