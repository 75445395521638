.containerBuyPokumi {
    position: absolute;
    top: 75%;
    font-size: 0.875em;
    width: 100%;
    display: flex;
    justify-content: center;
    .actionWarButton {
			padding: 0.25em 0.5em;
			// width: 70%;
			text-align: center;
			color: black;
			background-color: #26ded0;
			font-weight: 500;
			border-radius: 4px;
			cursor: pointer;
		
	}
}
@media (min-width: 768px) {
	.containerBuyPokumi {
		.actionWarButton {
            padding: 0.5em 0;
            width: 45%;
			
		}
	}
}
@media (min-width: 1024px) {
	.containerBuyPokumi {
		.actionWarButton {
            padding: 0.75em 0;
            width: 30%;
			
		}
	}
}
@media (min-width: 1440px) {
	.containerBuyPokumi {
		.actionWarButton {
            padding: 0.75em 0;
            width: 20%;
        
		}
	}
}