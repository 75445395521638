.containerRemainingWar {
	grid-column: 2/5;
	grid-row: 11/13;
	.h5 {
		margin: 0.5em 0;
		&:after {
			content: attr(data-text);
			transform: translateX(-100%);
			position: absolute;
			text-decoration: underline #26ded0;
			text-underline-offset: 3px;
		}
	}
	.remainingGames {
		display: inline-block;
		font-size: 0.75em;
		padding: 0.5em 0;
	}
	.remainingGames {
		&List {
			padding: 0.5em;
			font-size: 0.75em;
			background-color: #1c1c1c;
			box-shadow: 0 0 10px rgba(20, 20, 22, 0.25);
			border-radius: 8px;
			li {
				display: grid;
				grid-template-columns: repeat(3, 1fr);
				padding: 0.25em 0;
				&:first-child {
					padding-bottom: 0.5em;
					margin-bottom: 0.5em;
					border-bottom: solid 1px #b1b5c3;
					font-weight: 500;
					grid-column: 1/2;
				}
				&:last-child {
					padding-top: 0.5em;
					margin-top: 0.5em;
					border-top: solid 1px #b1b5c3;
					font-weight: 500;
					grid-column: 1/2;
				}
			}
		}
		&ListLeft {
			grid-column: 1/3;
			display: flex;
			align-items: center;
			.picture {
				width: 50%;
				// margin-right: 0.25em;
				.miniLogo {
					border-radius: 50%;
					border: 2px solid #fff;
				}
			}
			.holderName{
				overflow: hidden;
			}
		}
		&ListHead{
			:first-child {
				width:50%;
			}
		}
		&ListFooter {
			:first-child {
				width:45%;
			}
		}
		&ListRight {
			grid-column: 3;
			text-align: end;
			width: 100%;
		}
		&FirstHolder {
			padding-top: 0.5em;
		}
		&LastHolder {
			padding-bottom: 0.5em;
		}
	}
}
@media (min-width: 375px) {
	.containerRemainingWar {
		.remainingGames {
			&ListLeft {
				.picture {
					width: 25%;
				}
				.holderName{
					overflow: hidden;
				}
			}
		}
	}
}
@media (min-width: 425px) {
	.containerRemainingWar {
		.remainingGames {
			&ListLeft {
				.picture {
					width: 20%;
				}
				.holderName{
					overflow: hidden;
				}
			}
		}
		&ListRight {
			width: 95%;
		}
	}
}
@media (min-width: 768px) {
	.containerRemainingWar {
		.h5 {
			font-size: 1.25em;
			&:after {
				text-underline-offset: 4px;
			}
		}
		.remainingGames {
			padding: 1em 0;
			&List {
				font-size: 1em;
				padding: 0.75em;
			}
			&ListLeft {
				grid-column: 1/2;
				.picture {
					width: 10%;
					margin-right: 0.5em;
				}
			}
			&ListHead{
				:first-child {
					width:100%;
				}
			}
			&ListFooter {
				:first-child {
					width:100%;
				}
			}
		}
	}
}
@media (min-width: 1024px) {
	.containerRemainingWar {
		.h5 {
			font-size: 1.5em;
			&:after {
				text-underline-offset: 5px;
			}
		}
		.remainingGames {
			font-size: 0.825em;
			&List {
				font-size: 1em;
				padding: 1em;
			}
			&ListLeft {
				grid-column: 1/2;
				.picture {
					width: 10%;
					// margin-right: 0.5em;
				}
			}
		}
	}
}
@media (min-width: 1440px) {
	.containerRemainingWar {
		.h5 {
			font-size: 2.625em;
			&:after {
				text-underline-offset: 10px;
			}
		}
		.remainingGames {
			font-size: 1.25em;
			&List {
				font-size: 1.375em;
				padding: 1.25em;
			}
			&ListLeft {
				grid-column: 1/2;
				.picture {
					// width: 15%;
					// margin-right: 0.5em;
				}
				.holderName{
					overflow: hidden;
				}
			}
			&ListHead{
				:first-child {
					width:100%;
				}
			}
			&ListFooter {
				:first-child {
					width:100%;
				}
			}
		}
	}
}