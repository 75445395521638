.header {
	display: flex;
	justify-content: space-between;
	padding: 0.5em;
	position: relative;
	z-index: 3;
	picture {
		align-self: self-end;
	}
	.img {
		width: 5em;
		// height: 2.1875em;

	}
}
.loginButton {
	width: 5em;
	& > * {
		display: flex;
		justify-content: flex-end;
	}
}

@media (min-width: 375px) {
	.header{
		.img {
			width: 6em;
		}
	}

}
@media (min-width: 425px) {
	.header{
		.img {
			width: 6.5em;
		}
	}
}
@media (min-width: 768px) {
	.header{
		.img {
			width: 8.5em;
		}
	}
}
@media (min-width: 1024px) {
	.header{
		.img {
			width: 9.5em;
		}
	}

}
@media (min-width: 1440px) {
	.header{
		.img {
			width: 10.9375em;
		}
	}
}